<template>
  <div class="mx-auto w-full max-w-sm">
    <div class="mb-12">
      <img class="h-8 w-auto" src="@/master/assets/autralis/logo-rgb-autralis.png" alt="Workflow">
      <h2 class="mt-6 text-xl leading-9 font-bold text-gray-600">
        <translate>2 Factor authentication</translate>
      </h2>
    </div>
    <form action="" method="post" id="valid" class="mainForm">
      <div>
        <label for="id_code" class="block text-sm font-medium leading-5 text-gray-700">
          <translate>Authenticator code</translate>
        </label>
        <div class="mt-1 rounded shadow-sm">
          <input id="id_code" v-model="code" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
      </div>
      <div class="mt-6">
        <span class="block w-full rounded shadow-sm">
          <a id="login-button" @click="login"
             class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out cursor-pointer">
            <div v-if="loading"><span class="mt-1 mr-2"><span class="fas fa-spinner-third fa-spin"></span></span> <translate>Signing in</translate> ...</div>
            <div v-else><translate>Sign in</translate></div>
          </a>
        </span>
      </div>
    </form>
    <div class="mt-4 w-full flex flex-col items-center">
      <a @click="logOut"
         class="font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
        <translate>Log out</translate>
      </a>
    </div>
    <div class="mt-8">
      <Languages></Languages>
    </div>
  </div>
</template>


<script>
import router from "@/router";
import Languages from "@/master/components/Languages";
import axios from "axios";

export default {
  name: "LoginAuthenticator",
  components: {Languages},
  data: function () {
    return {
      code: ""
    }
  },
  methods: {
    login: function () {
      if(this.loading) return;
      try {
        this.errorMessage = null;
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        axios.post('/manager/api/v1/validate-2fa/',{ code: this.code}, {withCredentials: true}).then((response) => {
              if(response.status!==200) {
                this.loading = false;
                if(response.status>=400&&response.status<500) this.errorMessage = this.$gettext("Please retry the authentication code");
                if(response.status>=500)  this.errorMessage = this.$gettext("System error, please retry or contact Helpdesk");
                return;
              }
              this.loading = false;
              router.push('login-company');
            }
        ).catch(() => {
          this.loading = false;
          this.errorMessage = this.$gettext("Please verify username or password");
        });
      } catch (error) {
        this.loading = false;
        this.errorMessage = this.$gettext("System error, please retry or contact Helpdesk");
      }
    },
    logOut: function () {
      this.$store.commit('master/logOut');
      router.push('login');
    }
  }
}
</script>

<style scoped>

</style>