<template>
    <div class="w-full  flex flex-row justify-around">
      <a v-for="(language, key) in languages" :key="key" @click="changeLanguage(key)"
         class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
        {{ language }}
      </a>
    </div>
</template>

<script>
    export default {
      name: 'Languages',
      data: function() {
        return {
          languages: [],
          currentLanguage: null,
        }
      },

      created: function() {
        this.setLanguages()
      },
      methods: {
        setLanguages: function() {
          this.currentLanguage = this.$language.current;
          this.languages = { ...this.$language.available };
          delete this.languages[this.$language.current];
        },
        changeLanguage(ln) {
          this.currentLanguage = ln;
          this.$language.current = ln;
          this.setLanguages();
        }
      }
    };
</script>
